const Footer = () => {
    return (

        <footer id="footer" className="footer">

            <div className="footer-legal text-center">
                <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">

                    <div className="d-flex flex-column align-items-center align-items-lg-start">
                        <div className="copyright">
                            &copy; Copyright <strong><span>Aaroh Services</span></strong>. All Rights Reserved
                        </div>
                        <div className="credits">
                            Designed by <a href="https://bootstrapmade.com/">BootstrapMade (Herobiz)</a>
                        </div>
                    </div>

                    <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
                        
                        <a href="https://www.facebook.com/aaroh.s.16" target="_blank" className="facebook"><i className="bi bi-facebook"></i></a>
                        <a href="tel:+918669377177" className="instagram"><i className="bi bi-telephone-fill"></i></a>
                        <a href="mailto:contact@aarohservices.com"  className="google-plus"><i className="bi bi-envelope"></i></a>
                        
                    </div>

                </div>
            </div>

        </footer>


    )
}
export default Footer;