import './App.css';
import { Routes, Route } from 'react-router-dom';


import Header from './Components/Header';
import Home from './Components/Home';
import Aboutus from './Components/Aboutus';
import Webdesignservices from './Components/Webdesignservices';
import Automotivedesignservices from './Components/Automotivedesignservices';
import Projects from './Components/Projects';
import Contact from './Components/Contactus';
import Footer from './Components/Footer';
import Pagenotfound from './Components/Pagenotfound';


function App() {
    return (
        <div className="App" >
            <Header />

            <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/services/webdesignservices' element={<Webdesignservices />} />
                <Route exact path='/services/Automotivedesignservices' element={<Automotivedesignservices />} />
                <Route exact path='/projects' element={<Projects />} />
                <Route exact path='/aboutus' element={<Aboutus />} />
                <Route exact path='/contact' element={<Contact />} />
                <Route path="*" element={<Pagenotfound />} />
            </Routes>

            <Footer />
        </div>
    );
}




export default App;