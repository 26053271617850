const Webdesignservices = () => {
    return (
        <div>
            <section id="hero-static" className="hero-static d-flex align-items-center">
                <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
                    <h2><span>Our Web Design Services</span></h2>
                </div>
            </section>


            <section id="faq" className="faq">
                <div className="container-fluid" data-aos="fade-up">

                    <div className="row gy-4">

                        <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                            <div className="content px-xl-5">
                                <h3>We provide <strong>Following</strong></h3>

                            </div>

                            <div className="accordion accordion-flush px-xl-5" id="faqlist">

                                <div className="accordion-item" data-aos="fade-up" data-aos-delay="200">
                                    <h3 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                            <i className="bi bi-question-circle question-icon"></i>
                                            We Provide Website Designing
                                        </button>
                                    </h3>
                                    <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className="accordion-body">
                                            <p>You need to pay for Domain Name of your choice.</p>
                                            <p>You need to pay for Web Space.</p>
                                            <p>We will design your website.</p>
                                            <p>We will provide free Email Accounts(limited as per web space) of your website.</p>
                                            <p>We will also provide hosting of your website.</p>
                                            <p>website will come with 5 pages.</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="accordion-item" data-aos="fade-up" data-aos-delay="300">
                                    <h3 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                            <i className="bi bi-question-circle question-icon"></i>
                                            Business Website
                                        </button>
                                    </h3>
                                    <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className="accordion-body">
                                            <p>A website can make your business easy to find, easy to understand and easy to work with.</p>
                                            <p>A website allows you to stand out from the competition.</p>
                                            <p>When you have your own custom e-mail connected to your domain and website - it looks much more professional.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item" data-aos="fade-up" data-aos-delay="400">
                                    <h3 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                            <i className="bi bi-question-circle question-icon"></i>
                                            Personal Portfolio Website
                                        </button>
                                    </h3>
                                    <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className="accordion-body">
                                        You can connect all your social media accounts to your website - by doing this you maximize your chance of having people to notice you.
                                        </div>
                                    </div>
                                </div>


                                <div className="accordion-item" data-aos="fade-up" data-aos-delay="500">
                                    <h3 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                            <i className="bi bi-question-circle question-icon"></i>
                                            Convert UX Design to workable UI
                                        </button>
                                    </h3>
                                    <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className="accordion-body">
                                            <i className="bi bi-question-circle question-icon"></i> 
                                            If you have your UX design ready with you, we can convert it to the workable UI.      
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div id="webdesignimage" className="col-lg-5 align-items-stretch order-1 order-lg-2 img" >&nbsp;</div>
                    </div>

                </div>
            </section>



        </div>
    )
}
export default Webdesignservices;