import AutomotiveDesign from '../images/AutomotiveDesign.jpg';
import WebDesign from '../images/WebDesign.jpg';
import { NavLink} from 'react-router-dom';

const Carousel = () => {
    return (

        <section id="hero" className="hero carousel  carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">

            <div className="carousel-item active">
                <div className="container">
                    <div className="row justify-content-center gy-6">

                        <div className="col-lg-8 col-md-8">
                            <img src={WebDesign} alt="" className="img-fluid img" />
                        </div>

                        <div className="col-lg-9 text-center">
                            <h2>Website Design And Development</h2>
                            <NavLink exact="true" to="/services/webdesignservices" className="btn-get-started scrollto ">Get Started</NavLink>
                        </div>

                    </div>
                </div>
            </div>

            <div className="carousel-item">
                <div className="container">
                    <div className="row justify-content-center gy-6">

                        <div className="col-lg-8 col-md-8">
                            <img src={AutomotiveDesign} alt="" className="img-fluid img" />
                        </div>

                        <div className="col-lg-9 text-center">
                            <h2>Automotive Design Services</h2>
                            <NavLink exact="true" to="/services/Automotivedesignservices" className="btn-get-started scrollto ">Get Started</NavLink>
                        </div>

                    </div>
                </div>
            </div>


            <a className="carousel-control-prev" href="#hero" role="button" data-bs-slide="prev">
                <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
            </a>

            <a className="carousel-control-next" href="#hero" role="button" data-bs-slide="next">
                <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
            </a>

            <ol className="carousel-indicators"></ol>

        </section>


    )
}

export default Carousel;