import logo from '../images/AarohLogo.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <div>

            <header id="header" className="header fixed-top p-0" data-scrollto-offset="0">
                <div className="container-fluid d-flex align-items-center justify-content-between">


                    <NavLink exact="true" className="logo d-flex align-items-center  me-auto me-lg-0" to="/" >
                        <img className="logoimg ms-5" src={logo} alt="Aaroh Services" />
                    </NavLink>

                    <nav id="navbar" className="navbar me-5">
                        <ul>
                            <li><NavLink exact="true" className="nav-link " to="/">Home</NavLink></li>

                            <li className="dropdown"><NavLink exact="true" to=" "><span>Services</span> <i className="bi bi-chevron-down dropdown-indicator"></i></NavLink>
                                <ul>
                                    <li><NavLink exact="true" to="/services/webdesignservices">Web Design Services</NavLink></li>
                                    <li><NavLink exact="true" to="/services/Automotivedesignservices">Automotive Design Services</NavLink></li>
                                </ul>
                            </li>

                            <li><NavLink exact="true" to="/projects" className="nav-link " href="">Projects</NavLink></li>
                            <li><NavLink exact="true" to="/aboutus" className="nav-link " href="">About Us</NavLink></li>
                            <li><NavLink exact="true" to="/contact" className="nav-link " href="">Contact</NavLink></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle d-none"></i>
                    </nav>




                </div>
            </header>


        </div>
    )
}
export default Header;
