import { NavLink } from "react-router-dom";

const Pagenotfound = () => {
    return (
        <div>
            <section id="hero-static" className="hero-static d-flex align-items-center">
                <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
                    <h2><span>404 Page Not Found</span></h2>
                </div>
            </section>
            <section id="faq" className="faq">
                <div className="container-fluid" data-aos="fade-up">
                    <div className="row gy-4">
                        <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                            <div className="content px-xl-5">
                                <h3>WE ARE SORRY, PAGE NOT FOUND!</h3>
                                <h4>
                                    The page you are looking for might have been removed had its name 
                                    changed or temporarily unavailable.
                                </h4>
                                <NavLink to="/">Back to homepage</NavLink>
                            </div>
                        </div>
                        <div id="pagenotfoundimg" className="col-lg-5 align-items-stretch order-1 order-lg-2 img" >&nbsp;</div>
                    </div>

                </div>
            </section>            
        </div>
    );
}

export default Pagenotfound;