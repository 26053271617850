import emailjs from 'emailjs-com';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Contactus = () => {

    const [displayform,setform]=useState(1);
    const [displaloading,setloading]=useState(0);
    const [displaysuccess,setsuccess]=useState(0);
    const [displayerror,seterror]=useState(0);

    function sendEmail(e) {
        e.preventDefault();
        setform(0);
        setloading(1);

        emailjs.sendForm('service_5og6x4c', 'template_snzkh6i', e.target, '3F6KNAzEvX_LJo4yr')
          .then((result) => {
            setloading(0);
            setsuccess(1); 
            e.target.reset();
          }, (error) => {
              setloading(0);
              seterror(1);
              e.target.reset();
          });
      }
    

    return (
        <div>
            <section id="hero-static" className="hero-static d-flex align-items-center">
                <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
                    <h2><span>Contact Us</span></h2>
                </div>
            </section>

            <section id="contact" className="contact">

                <div className="container">
                    <div className="row gy-5 gx-lg-5">
                        <div className="col-lg-4">
                            <div className="info">
                                <h3>Get in touch</h3>
                                <p>If you have any questions or need help, please fill out the form. </p>

                                <div className="info-item d-flex">
                                    <i className="bi bi-geo-alt flex-shrink-0"></i>
                                    <div>
                                        <h4>Location:</h4>
                                        <p>Pimpri-Chichwad, Pune</p>                                        
                                    </div>
                                </div>

                                <div className="info-item d-flex">
                                    <i className="bi bi-envelope flex-shrink-0"></i>
                                    <div>
                                        <h4>Email:</h4>
                                        <p>contact@aarohservices.com</p>
                                    </div>
                                </div>

                                <div className="info-item d-flex">
                                    <i className="bi bi-phone flex-shrink-0"></i>
                                    <div>
                                        <h4>Call:</h4>
                                        <p>+91 8669377177</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8">
                            {(displayform)? <form onSubmit={sendEmail} className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <input type="text" name="User_Name" className="form-control" autoComplete='off' id="name" placeholder="*Your Name" required />
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" className="form-control" name="User_Email" autoComplete='off' id="email" placeholder="*Your Email" required />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" className="form-control" name="Subject" autoComplete='off' id="subject" placeholder="*Subject" required />
                                </div>
                                <div className="form-group mt-3">
                                    <textarea className="form-control" name="Message"autoComplete='off' placeholder="*Message" required></textarea>
                                </div>
                                <div className="text-center"><button type="submit">Send Message</button></div>
                            </form> :null }
                            <div className="my-3">
                                    {(displaloading)?<div className="loading">Loading</div>:null}
                                    {(displayerror)?<div className="error-message">Sorry!! There is some problem.<NavLink exact="true" className="nav-link " to="/">Go To HomePage</NavLink></div>:null}
                                    {(displaysuccess)?<div className="sent-message">Your message has been sent. Thank you! <NavLink exact="true" className="nav-link " to="/">Go To HomePage</NavLink></div>:null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Contactus;