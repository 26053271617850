import BIW from '../images/BIWImage.jpg';
import Fixture from '../images/FixtureDesignImage.jpg';
import Seating from '../images/SeatingImage.jpg';
import Kinamatic from '../images/BIWImage.jpg';
import Ergonomics from '../images/ErgonomicDesignImage.jpg';
import Resource from '../images/ResourceProviderservice.jpg';

const Automotivedesignservices = () => {
    return (
        <div>
            <section id="hero-static" className="hero-static d-flex align-items-center">
                <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
                    <h2><span>Our Automotive Design Services</span></h2>
                </div>
            </section>



            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-out">
                    <div className="row g-5">
                        <div className="col-lg-7 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3><em>BIW (Body In White)</em></h3>
                            <p> Parametric design of BIW Components.</p>
                            <p>Weld Spot Creation</p>
                            <p>Drawing Creation.</p>
                        </div>

                        <div className="col-lg-5 col-md-6 order-first order-md-last d-flex align-items-center">
                            <div className="img">
                                <img src={BIW} alt="Body In White" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-out">
                    <div className="row g-5">
                        <div className="col-lg-7 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3><em>BIW Fixture Design</em></h3>
                            <p> Design of checking/Welding Fixture for BIW Parts</p>
                            <p>Carry out GUN Study for Fixture Design</p>
                            <p>Prepare Drawings for mfg.</p>
                        </div>

                        <div className="col-lg-5 col-md-6 order-first order-md-last d-flex align-items-center">
                            <div className="img">
                                <img src={Fixture} alt="BIW Fixture Design" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-out">
                    <div className="row g-5">
                        <div className="col-lg-7 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3><em>Seating System</em></h3>
                            <p> Seating Structure Design</p>
                            <p>Kinematic Simulation of Seating Structure</p>
                            <p>CAD design of Seating Components</p>
                        </div>
                        <div className="col-lg-5 col-md-6 order-first order-md-last d-flex align-items-center">
                            <div className="img">
                                <img src={Seating} alt="Seating Design" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-out">
                    <div className="row g-5">
                        <div className="col-lg-7 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3><em>Kinamatic Simulation</em></h3>
                            <p> Kinematic Simulation for STUDY of Clash, Output action of system</p>
                            <p>Side door Glass simulation</p>
                            <p>Creating Envelope file for Clash Study</p>
                        </div>

                        <div className="col-lg-5 col-md-6 order-first order-md-last d-flex align-items-center">
                            <div className="img">
                                <img src={Kinamatic} alt="Kinamatic Simulation" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-out">
                    <div className="row g-5">
                        <div className="col-lg-7 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3><em>Ergonomic Design</em></h3>
                            <p>Mankind interface study in design of Product</p>
                            <p>Check Human reach Study for different positions of System and Mankind</p>
                        </div>

                        <div className="col-lg-5 col-md-6 order-first order-md-last d-flex align-items-center">
                            <div className="img">
                                <img src={Ergonomics} alt="Ergonomic Design" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-out">
                    <div className="row g-5">
                        <div className="col-lg-7 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3><em>Design Resource Provider</em></h3>
                            <p>We are the BRIDGE between Market Requirement and Design Resource.</p>
                            <p>We prepare candidate for Design Industry requirements.</p>
                            <p>Not only we Provide “DESIGN RESOURCE” but also we make them ready for customer requirements.(CAD skills and Domain skills)</p>
                        </div>

                        <div className="col-lg-5 col-md-6 order-first order-md-last d-flex align-items-center">
                            <div className="img">
                                <img src={Resource} alt="Automotive Resource Provider" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Automotivedesignservices;