import Carousel from './Carousel';
import Whatweprovide from './Whatweprovide';

const Home = () => {

    return (
        <div>
            <Carousel />
            <Whatweprovide />
        </div>
    )
}
export default Home;