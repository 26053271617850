
import AboutUsImg from '../images/About.jpg';
import WhyChooseUs from '../images/WhyChooseUs.svg'

const Aboutus = () => {
    return (

        <div>
            <section id="hero-static" className="hero-static d-flex align-items-center">
                <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
                    <h2><span>About Us</span></h2>
                    <h3>We Design Creativity</h3>
                </div>
            </section>

            <section id="features" className="features">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <div className="tab-content">

                        <div className="tab-pane active show">
                            <div className="row gy-4">
                                <div className="col-lg-8 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
                                    <h3>Why Choose Us</h3>

                                    <ul>
                                        <li><i className="bi bi-check-circle-fill"></i> Aaroh Services a Pune based firm  providing 'Web Design Services' , 'Automative Design Services', 'Automotive Service Provider'.</li>
                                        <li><i className="bi bi-check-circle-fill"></i> We aims to provide high quality services at affordable cost so that we can help your business grow..</li>

                                    </ul>

                                </div>
                                <div className="col-lg-4 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                                    <img src={WhyChooseUs} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section-header">
                <h2>Services We Provide</h2>
            </div>

            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                    <div className="row g-4 g-lg-5" data-aos="fade-up" data-aos-delay="200">

                        <div className="col-lg-4">
                            <div className="about-img">
                                <img src={AboutUsImg} className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <ul className="nav nav-pills mb-3">
                                <li><a className="nav-link active" data-bs-toggle="pill" href="#tab2">Web Design Services</a></li>
                                <li><a className="nav-link" data-bs-toggle="pill" href="#tab3">Automative Design Consultation</a></li>
                                <li><a className="nav-link" data-bs-toggle="pill" href="#tab4">Design Resource Provider</a></li>
                            </ul>



                            <div className="tab-content">

                                <div className="tab-pane fade show active" id="tab2">
                                    <p className="fst-italic">Why Choose Us For Web Design Services</p>

                                    <div className="d-flex align-items-center mt-4">
                                        <i className="bi bi-check2"></i>
                                        <h4>To Expand your Business and make it Expose to world.</h4>
                                    </div>

                                    <div className="d-flex align-items-center mt-4">
                                        <i className="bi bi-check2"></i>
                                        <h4>We make website of your business at affordable cost.</h4>
                                    </div>

                                    <div className="d-flex align-items-center mt-4">
                                        <i className="bi bi-check2"></i>
                                        <h4>You will get Email IDs of your company name.</h4>
                                    </div>
                                </div>


                                <div className="tab-pane fade show" id="tab3">

                                    <p className="fst-italic">Why Choose Us For Automative Design Consultation</p>
                                    <div className="d-flex align-items-center mt-4">
                                        <i className="bi bi-check2"></i>
                                        <h4>Design Consultation (We Provide Design Solution in following area)</h4>
                                    </div>
                                    <p></p>
                                    <p>BIW</p>
                                    <p>BIW Fixture Design</p>
                                    <p>Seating System</p>
                                    <p>Kinematic Simulation</p>
                                    <p>Ergonomics Design</p>

                                </div>

                                <div className="tab-pane fade show" id="tab4">

                                    <p className="fst-italic">Why Choose Us For Automative Services Resource Provider</p>

                                    <div className="d-flex align-items-center mt-4">
                                        <i className="bi bi-check2"></i>
                                        <h4>Design Resource Provider</h4>
                                    </div>
                                    <p></p>
                                    <p>We are the BRIDGE between Market Requirement and Design Resource.</p>
                                    <p>We prepare candidate for Design Industry requirements.</p>
                                    <p>Not only we Provide “DESIGN RESOURCE” but also we make them ready for customer requirements.(CAD skills and Domain skills)</p>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>

        </div>
    )
}

export default Aboutus;