
import ConsultationService from '../images/ConsultationService.jpg';
import ResourceProviderservice from '../images/ResourceProviderservice.jpg';
import WebDesignService from '../images/WebDesignService.jpg';

import { NavLink } from 'react-router-dom';


const Whatweprovide = () => {
    return (

        <section id="services" className="services">
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <h2>What We Provide</h2>
                </div>

                <div className="row gy-5">

                    <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                        <div className="service-item">
                            <div className="img">
                                <img src={WebDesignService} className="img-fluid" alt="" />
                            </div>
                            <div className="details position-relative">
                                <div className="icon">
                                    <i className="bi bi-activity"></i>
                                </div>
                                <NavLink exact="true" to="/services/webdesignservices" className="stretched-link">
                                    <h3>Web Design Services</h3>
                                </NavLink>

                                <p>We design "Business websites" as well as "Personal portfolio websites".
                                    Our aim is to provide you Quality product at best suitable price which will help your business to prosper.</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                        <div className="service-item">
                            <div className="img">
                                <img src={ConsultationService} className="img-fluid" alt="" />
                            </div>
                            <div className="details position-relative">
                                <div className="icon">
                                    <i className="bi bi-broadcast"></i>
                                </div>
                                <NavLink exact="true" to="/services/Automotivedesignservices" className="stretched-link">
                                    <h3>Automotive Design Consultation</h3>
                                </NavLink>
                                <p>We will be providing DESIGN CONSULTATION in BIW, BIW Fixture Design, Seating System, Kinematic Simulation, Ergonomics Design and many more</p>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                        <div className="service-item">
                            <div className="img">
                                <img src={ResourceProviderservice} className="img-fluid" alt="" />
                            </div>
                            <div className="details position-relative">
                                <div className="icon">
                                    <i className="bi bi-easel"></i>
                                </div>
                                <NavLink exact="true" to="/services/Automotivedesignservices" className="stretched-link">
                                    <h3>Automotive Design Resource Provider</h3>
                                </NavLink>
                                <p>We are bridge between market requirements and design resource.
                                    We prepare candidates for Design Industry requirements.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>





    )
}
export default Whatweprovide;