import P_1 from '../images/Projects/1.png';
import P_2 from '../images/Projects/2.png';
import P_3 from '../images/Projects/3.png';
import P_4 from '../images/Projects/4.png';
import P_5 from '../images/Projects/5.png';
import P_6 from '../images/Projects/6.png';
import P_7 from '../images/Projects/7.png';




const Projects = () => {
    return (
        <div>

            <section id="hero-static" className="hero-static d-flex align-items-center">
                <div className="container d-flex flex-column justify-content-center align-items-center text-center position-relative" data-aos="zoom-out">
                    <h2><span>Some Projects </span></h2>
                </div>
            </section>

            <section id="blog" className="blog">
                <div className="container" data-aos="fade-up">
                    <div className="row g-5">
                        <div className="col-lg-12">
                            <div className="row gy-4 posts-list">

                                <div className="col-lg-4">
                                    <article className="d-flex flex-column">
                                        <div className="post-img">
                                            <img src={P_1} alt="" className="img-fluid" />
                                        </div>
                                        <h2 className="title">
                                            <p>Yoga Institute</p>
                                        </h2>
                                        <div className="content">
                                            <p>
                                                Website is designed for a center of Yoga and Nisargopchar.
                                            </p>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-lg-4">
                                    <article className="d-flex flex-column">
                                        <div className="post-img">
                                            <img src={P_2} alt="" className="img-fluid" />
                                        </div>
                                        <h2 className="title">
                                            <p>Cyclist Club</p>
                                        </h2>
                                        <div className="content">
                                            <p>
                                                Designed to gathered information and to establish co-ordination between cyclist in PCMC , Pune area.
                                            </p>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-lg-4">
                                    <article className="d-flex flex-column">
                                        <div className="post-img">
                                            <img src={P_3} alt="" className="img-fluid" />
                                        </div>
                                        <h2 className="title">
                                            <p>Service Providing company. </p>
                                        </h2>
                                        <div className="content">
                                            <p>
                                                Website of Company providing services to people at their doorstep.
                                                In this websites there are different categories of services which are provided in different cities.
                                            </p>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-lg-4">
                                    <article className="d-flex flex-column">
                                        <div className="post-img">
                                            <img src={P_4} alt="" className="img-fluid" />
                                        </div>
                                        <h2 className="title">
                                            <p>Service Providing company </p>
                                        </h2>
                                        <div className="content">
                                            <p>
                                                Website is designed for the Company to feature out its services and facilitate users to avail them online.
                                            </p>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-lg-4">
                                    <article className="d-flex flex-column">
                                        <div className="post-img">
                                            <img src={P_5} alt="" className="img-fluid" />
                                        </div>
                                        <h2 className="title">
                                            <p>Featuring Product Details </p>
                                        </h2>
                                        <div className="content">
                                            <p>
                                                Website design to enlist the features of new launched product.
                                            </p>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-lg-4">
                                    <article className="d-flex flex-column">
                                        <div className="post-img">
                                            <img src={P_6} alt="" className="img-fluid" />
                                        </div>
                                        <h2 className="title">
                                            <p>Social Organization</p>
                                        </h2>
                                        <div className="content">
                                            <p>
                                                Website of a Non-Profit Organization featuring its services, activities conducted, etc.
                                            </p>
                                        </div>
                                    </article>
                                </div>

                                <div className="col-lg-4">
                                    <article className="d-flex flex-column">
                                        <div className="post-img">
                                            <img src={P_7} alt="" className="img-fluid" />
                                        </div>
                                        <h2 className="title">
                                            <p>Business-Enterpreneur Commuication</p>
                                        </h2>
                                        <div className="content">
                                            <p>
                                                Website is designed to establish link between Businesses and Enterpreneur, where they can commuicate and link with each other.
                                            </p>
                                        </div>
                                    </article>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Projects;